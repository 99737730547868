import React from 'react'
import { useLocation } from 'react-router-dom'

import BaseLayout from 'blocks/layout/base-layout';

import { Button, EmailField, Form, Link, Heading, P, PasswordField, Section } from 'components'
import { NavigationTarget } from 'data'
import { useAppWideState, useFormField, useRedirect } from 'hooks'
import { builder } from 'routes'

import { postData } from 'helpers/post-data'

import './index.css'

const SignIn: React.FC = () => {
  const state: SignInState = useLocation().state
  // If react-router state contains a target, go there after signing in.
  // Otherwise, default to the all groups page.
  const url = state?.afterSignIn ?? builder.group.all
  const after = new NavigationTarget({ url })

  const redirect = useRedirect()
  const { isSignedIn, signIn, showError } = useAppWideState()

  const email = useFormField('')
  const password = useFormField('')

  // supposed to be in useEffect?
  if (isSignedIn) {
    redirect(after)
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const result = await postData('/api/auth/password/signin', { email, password })
      signIn(result.data.token)
      redirect(after)
    } catch (e) {
      console.error(e)
      showError('Incorrect email address or password')
    }
  }

  const disabled = !email.isValid || !password.isValid
  return (
    <BaseLayout showNavigation showNavigationBackButton>
      <Section id="SignIn">
        <Heading>Sign In</Heading>
        <P>Please sign in to access your AutoGive groups. Don't have an account yet? <Link to={builder.group.create}>Sign up</Link></P>
        <Form onSubmit={onSubmit}>
          <EmailField binding={email} intent="signin" isSignInPage={true} required />
          <PasswordField binding={password} required type="current" />

          <Button type="submit" text="Sign In" disabled={disabled} />
        </Form>
        <P centered>Forgot your password? <Link to={builder.site.requestPasswordReset}>Tap here</Link></P>
      </Section>
    </BaseLayout>
  )
}

export default SignIn
