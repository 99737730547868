import React, { useEffect, useState } from 'react'

import { SignInLink, TextField } from 'components'
import { useAppWideState } from 'hooks'

import { postData } from 'helpers/post-data'

enum Availability {
  NotChecked,
  Checking,
  Available,
  Unavailable,
  Invalid,
}

type Intent =
  | 'contact'
  | 'inviteRecipient'
  | 'register' // To create a new user. Will check for uniqueness in backend
  | 'signin'
  | 'update' // Updating own info


const doCheck = async (email: string, setAvailability: (a: Availability) => void): Promise<void> => {
  try {
    setAvailability(Availability.Checking)
    const result = await postData('/api/auth/register/isAvailable', { email })
    const isAvailable: boolean = result.data.email
    if (isAvailable) {
      setAvailability(Availability.Available)
    } else {
      setAvailability(Availability.Unavailable)
    }
  } catch (error) {
    console.error(error)
    setAvailability(Availability.Invalid)
  }
}

interface Props extends FormFieldProps<string> {
  intent: Intent
  onDataChange?: (newData: boolean) => void
  isSignInPage?: boolean
}
const EmailField: React.FC<Props> = ({
  binding,
  inline,
  intent,
  name,
  required,
  onDataChange,
  isSignInPage = false
}) => {
  const [availability, setAvailability] = useState(Availability.NotChecked)
  const [isFormFieldValid, setIsFormFieldValid] = useState(false)
  const { isSignedIn } = useAppWideState()

  // Reset availability check with value changes
  useEffect(() => {
    setAvailability(Availability.NotChecked)
    if (binding.value.includes('@') && binding.value.includes('.')) doCheck(binding.value, setAvailability)
  }, [binding.value])


  const shouldRunRemoteCheck = isFormFieldValid
    && binding.value !== '' // in case not required, still don't run if empty
    && (intent === 'register' || intent === 'update')
    && availability === Availability.NotChecked

  // Run a remote check when unchecked on load
  useEffect(() => {
    if (binding.value !== '' && availability === Availability.NotChecked) {
      doCheck(binding.value, setAvailability)
    }
  }, [])


  // Push validity upstream, layering in uniqueness
  const bindingSetIsValid = binding.setIsValid
  useEffect(() => {
    if (intent === 'register' || intent === 'update') {
      bindingSetIsValid(isFormFieldValid && availability === Availability.Available)
    } else {
      bindingSetIsValid(isFormFieldValid)
    }

    if (availability !== 3) sendDataToParent(isFormFieldValid)  

  }, [availability, bindingSetIsValid, intent, isFormFieldValid])

  useEffect(() => {
    if (availability === 3) sendDataToParent(false)    
  }, [availability]) 

  const sendDataToParent = (errorMsg: boolean) => {
    if (onDataChange) onDataChange(errorMsg)
  }

  let errorMessage: React.ReactNode
  if (availability === Availability.Unavailable) {
    if (isSignedIn) {
      errorMessage = <>Already in use.</>
    } else if (isSignInPage) {
      errorMessage = ''
    } else {
      errorMessage = <>Already in use. <SignInLink /></>
    }
  }
  // } else if (availability === Availability.Invalid) {
  //   errorMessage = "Invalid email"
  // }


  // Future: when we add other intents, update placeholders and validation data
  // accordingly
  let placeholder = 'Your Email Address'
  if (intent === 'inviteRecipient') {
    placeholder = 'Recipient Email Address'
  }

  // Run the check early on blur; either this or the debounced onChange result
  // will "win" (and do the same thing)
  const onBlur = () => {
    if (shouldRunRemoteCheck) {
      doCheck(binding.value, setAvailability)
    }

    if (required && binding.value === '') {
      sendDataToParent(false)  
    }
  }

  // Future: once fully switched to email sign in, update intent=register to do
  // this too.
  const autoComplete = intent === 'signin' ? 'username' : 'email'

  return (
    <TextField
      autoComplete={autoComplete}
      binding={{...binding, setIsValid: setIsFormFieldValid }}
      errorMessage={errorMessage}
      inline={inline}
      name={name}
      onBlur={onBlur}
      placeholder={placeholder}
      required={required}
      type="email"
      validationMessage="Email is required"
    />
  )
}

export default EmailField
