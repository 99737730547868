import React, { useEffect, useState } from 'react'
import { readError } from 'helpers'

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import type {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripePaymentRequestButtonElementClickEvent,
} from '@stripe/stripe-js'

import {
  EmailField,
  PaymentBreakdown,
  PhoneNumberField,
  TOSLinks,
} from 'components'
import { useFormField, useAppWideState } from 'hooks'

import breakdownToPaymentRequestOptions from './breakdownToPaymentRequestOptions'
import type { PayerAndPaymentMethodData } from './ModalPaymentForm'

interface Props {
  breakdown: Api.Breakdown
  collectPayerEmail: boolean // see below probably
  collectPayerPhone: boolean // TEMPORARY - will be removed when we have a design/flow to handle an already-used number in the callback
  disabled?: boolean
  onPaymentSubmit: (data: PayerAndPaymentMethodData) => Promise<boolean>
  paymentRequest: PaymentRequest
}
const PaymentRequestButton: React.FC<Props> = ({
  breakdown,
  collectPayerEmail,
  collectPayerPhone,
  disabled,
  onPaymentSubmit,
  paymentRequest,
}) => {
  const { isSignedIn, showError } = useAppWideState()
  const [emailValid, setEmailValid] = useState(false)
  const email = useFormField('')
  const phone = useFormField('') 

  const handleDataChange = (newData: boolean) => {
    setEmailValid(newData)
  }

  const onClick = (e: StripePaymentRequestButtonElementClickEvent) => {
    const input = document.querySelector('input[type="email"]') as HTMLInputElement | null
    if (input !== null) {
      input.blur() 
    }

    if ((!isSignedIn) && (!emailValid || !email.isValid || disabled)) e.preventDefault()
  }

  const disableButton = disabled
    // || (!isSignedIn && !phone.isValid)
    || (collectPayerEmail && !email.isValid)

  // If amount changes, update payment request
  useEffect(() => {
    // If you hit the PR button when the amount is focused, the result
    // comes in while the sheet is being displayed. Upstream disable
    // state should handle this, but if this error fires, it should get
    // investigated. The final place I've been able to reproduce this is AFTER
    // the user hit the native payment confirmation, which was caused by an
    // intenended reference equality check - an effect depending on object. But
    // as of #962 it really should be fixed (famous last words).
    if (paymentRequest.isShowing()) {
        console.error('Tried to update payment sheet while amount is showing.')
        return
    }
    // Remove any existing events - on() creates a stack of them
    paymentRequest.off('paymentmethod')
    // Update the total and line-items
    paymentRequest.update(breakdownToPaymentRequestOptions(breakdown))
    // Bind the new submit callback
    paymentRequest.on('paymentmethod', async (pmEvent: PaymentRequestPaymentMethodEvent) => {
      const callbackData: PayerAndPaymentMethodData = {
        payerEmail: collectPayerEmail ? email.value : (pmEvent.payerEmail ?? null),
        payerName: pmEvent.payerName ?? null,
        // See above - use local form value instead
        // payerPhone: pmEvent.payerPhone ?? null, <-- go back to this
        payerPhone: collectPayerPhone ? phone.value : null,
        paymentMethodId: pmEvent.paymentMethod.id,
      };
      try {
        const success = await onPaymentSubmit(callbackData);
        pmEvent.complete(success ? 'success' : 'fail');
      } catch (error) {
        console.error(error);
        showError(readError(error, "Payment failed, please try again"))
        pmEvent.complete('fail');
      }
    })

  }, [collectPayerEmail, collectPayerPhone, email.value, phone.value, paymentRequest, breakdown])

  return (
    <>
      {collectPayerEmail && <EmailField
        binding={email}
        intent="register"
        onDataChange={handleDataChange}
        required
      />}
{/*      {collectPayerPhone && <PhoneNumberField
        binding={phone}
        intent="register"
        required
      />}*/}
      <PaymentBreakdown breakdown={breakdown} />
      {/*<TOSLinks />*/}
      <div className="PaymentFormPaymentRequestButton">
        <PaymentRequestButtonElement
          onClick={onClick}
          options={{ paymentRequest }}
        />
        {/*{disableButton && <DisablingOverlay />}*/}
      </div>
    </>
  )
}

const DisablingOverlay: React.FC = () => {
  return (
    <div className="PaymentFormPaymentRequestButtonDisablingOverlay"></div>
  )
}

export default PaymentRequestButton
